.service-icons {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    justify-items: center;
    text-align: center;
  }
  
  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
    cursor: pointer;
}
.service:hover{
      transform: scale(1.2);

  }
  
  .service p {
    margin-top: 8px;
  }
  .icons-map{
    font-size: 36px;
    color: #E34E30;
  }
  .service-headline{
    padding: 36px;
  }
  .services{
    padding: 36px;
  }

  .services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
    cursor: pointer;
  }
  
  .services .icon-box::before {
    content: "";
    position: absolute;
    background: #aff4e9;
    right: -60px;
    top: -40px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    transition: all 0.3s;
    z-index: -1;
  }
  
  .services .icon-box:hover::before {
    background: #1bbca3;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  
  .services .icon {
    margin: 0 auto 20px auto;
    padding-top: 10px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 60px;
    height: 50px;
    background: #1bbca3;
    transition: all 0.3s ease-in-out;
    font-size: 26px;
    color: #fff;
  }
  
  .services .icon i {
    font-size: 36px;
    line-height: 1;
    color: #fff;
  }
  
  .services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .title a {
    color: #425451;
  }
  
  .services .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  .services .icon-box:hover .title a,
  .services .icon-box:hover .description {
    color: #fff;
  }
  
  .services .icon-box:hover .icon {
    background: #fff;
  }
  
  .services .icon-box:hover .icon .svg-inline--fa {
    color: #1bbca3;
  }
  .service-animate-section{
    display: flex;
    justify-content: center;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .services-images-section {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .service-images {
    width: 300px;
    border-radius: 10px;
    padding: 10px;
  }
  
  .text-container {
    flex: 1;
    padding: 20px;
  }
  
  .text-container h4 {
    margin-top: 0; 
    font-size: 20px; 
    color: #333; 
  }
  
  .text-container p {
    margin: 10px 0 0; 
    line-height: 1.5; 
    color: #666; 
  }
  @media screen and (max-width:767px) {
    .service-icons {
      grid-template-columns: auto;
    }
    .service-headline{
      font-size: 25px;
      padding: 0px 36px;
    }
    .services-images-section{
     flex-direction: column;
    }
    .service-images{
      width: 230px;
    }
    .service-animate-section{
      flex-direction: column;
    }
    .services .icon-box{
      margin-top: 2rem;
    }
  }
  
  