.banner-image {
    width: 100%;
    height: auto; 
    box-sizing: border-box;
  }
.btn-banner-image {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 100;
  background: #e34e30 !important;
  color: #fff !important;
  padding: 10px 20px;
  width: auto;
  border: none;
}

.btn-banner-image:hover {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 100;
  background: #fff !important;
  color: #e34e30 !important;
  border: 1px solid #e34e30 !important;
  padding: 10px 20px;
  width: auto;
}

  .banner-container {
    position: relative;  
    overflow: hidden;
  }
  .banner-image-section{
    padding-top: 36px;
  }
  .banner-image-h{
    padding-top: 22px;
  }
  @media screen and (max-width:767px) {
  .banner-description{
    width: 100%;
    height: 50%;
    top:55%
  }
  .banner-image-h{
    padding-top: 0px;
    font-size: 22px;
  }
.banner-image-section{
  padding-top: 0px;
}


  }
  
  
  