.footer {
    background-color: #282c34;
    color: white;
    text-align: center;
    padding: 20px 10px;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: start;
}

.footer-section {
    flex: 1 1 180px; /* Minimum width of 180px before wrapping */
    margin: 10px;
    color: #ccc;
}

.footer-section h4 {
    color: #fff;
    margin-bottom: 15px;
}

.footer-section a {
    color: #ccc;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 12px; /* Increased gap between icon and text */
    margin-bottom: 8px;
}

.footer-section a:hover {
    color: #fff;
    text-decoration: underline;
}

a svg {
    transition: transform 0.2s ease-in-out;
}

a:hover svg {
    transform: scale(1.1);
}
.icon-container {
    position: fixed;
    bottom: 20px; 
    right: 20px; 
}

.whatsapp-icon {
    width: 100%; 
    height: auto;
    cursor: pointer;
}


@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        text-align: center;
    }
    .whatsapp-icon{
        width: 80%;
    }
    .icon-container {
        bottom: 5px; 
        right: 5px; 
    }
}
