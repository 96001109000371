.navbar {
    background-color: #ffffff; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .logo {
   width: 80px;
  }
  
  .navbar-menu {
    display: flex;
    align-items: center;
  }
  
  .navbar-menu.open {
    display: block;
  }
  
  .navbar-nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .nav-item {
    margin-right: 10px;
    position: relative;
  }
  .submenu {
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px rgba(0,0,0,0.15);
    width: auto; 
    padding: 10px;
    z-index: 1000;
  }
  
  .nav-item:hover .submenu {
    display: block;
    /* display: flex; */
    color: green;
    font-size: 17px;
    text-align: center;
  }
  .nav-link:hover{
   color: blue;
  }
  .sub-menu-list{
    font-size: 16px;
  }
  .column {
    float: left;
    width: 200px; /* Adjust as needed */
    padding: 10px;
  }
  
  .column h4 {
    margin-top: 0;
  }
  
  .column ul {
    list-style: none;
    padding: 0;
  }
  
  .column li a {
    display: block;
    padding: 5px 0; /* Padding for each submenu item */
    color: black;
    text-decoration: none;
   text-align: center;
  }
  
  .column li a:hover {
    color: red;
  }
  
  /* Clearfix for float */
  .submenu::after {
    content: "";
    display: table;
    clear: both;
  }
  .nav-link {
    color: #202020;
    text-decoration: none;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-link svg {
    margin-left: 5px; 
  }
  .menu-icon {
    display: none;
    cursor: pointer;
  }
  .offer-section{
    background-color: pink;
    color: black;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
  .blinking-text {
    animation: blinkText 1s linear infinite;
  }
  
  @keyframes blinkText {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  
  @media screen and (max-width: 768px) {
    .navbar-menu {
      display: none;
    }
  
    .menu-icon {
      display: block;
    }
  
    .menu-icon i {
      font-size: 2rem;
      padding-top: 10px;
      margin-left: 6rem;
     margin-top: -6rem;
      position: absolute;
    }
    .fas{
      padding-left: 20px;
    }
  .menu-icon{
    position: relative;
    margin-left: 13rem;
    margin-top: -5rem;
  }
    .navbar-container {
      flex-direction: column;   
      padding: 30px 0px;
    }
  .offer-section{
    display: none;
  }
    .navbar-logo {
      margin-bottom: 10px;
    }
    .navbar-nav{
      flex-direction: column;
      margin-top: 3rem;
    }
   .nav-link{
    padding: 10px;
   }
   .logo{
    margin-right: 12rem;
   }
   .navbar-menu.active{
    display: flex;
   }
  }
  