.about-us-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .text-container {
    flex: 1;
    margin-right: 20px;
  }
  
  .image-container {
    flex: 1;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  @media screen and (max-width:767px) {
    .about-us-container{
        flex-direction: column;
    }
  }
  