.submenu-banner{
    width: 100%;
    height: 365px;
}
.sub-service-animate-section{
    display: flex;
    flex-wrap: wrap;
    width: 60%;
  }
  .submenu-form{
    width: 40%;
  }
  .submenu-grid{
    display: flex;
    margin: 2rem 0rem;
    padding-top: 36px;
   
  }
  .submenu-text{
    text-align: center;
    color: #FFFFFF;
  }
  .sub-icon-box{
    margin-right: 1rem;
    width: 27.33%;
    height: 33%;
    box-sizing: border-box;
  }
  .services .sub-icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
    cursor: pointer;
  }
  .services .sub-icon-box::before {
    content: "";
    position: absolute;
    background: #aff4e9;
    right: -60px;
    top: -40px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    transition: all 0.3s;
    z-index: -1;
  }
  .services .sub-icon-box:hover::before {
    background: #1bbca3;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  .services .sub-icon-box:hover .title a,
  .services .sub-icon-box:hover .description {
    color: #fff;
  }
  .services .sub-icon-box:hover .icon {
    background: #fff;
  }
  
  .services .sub-icon-box:hover .icon .svg-inline--fa {
    color: #1bbca3;
  }
  .contact-form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    background: linear-gradient(135deg, rgba(94,0,131,1) 0%, rgba(0,212,255,1) 100%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #FFFFFF;
  }
  
  input[type="text"],
  select,
  button {
    width: 100%; 
    height: 40px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  select {
    height: 40px;
    font-size: 16px;
  }
  
  button {
    width: 100%;
    background: #EF0A2B;
    transition: background-color 0.3s ease;
    color: white;
    cursor: pointer;
    height: 50px;
    border: none;
    font-size: 18px;
  }
  
  button:hover {
    background: #B90723;
  }
  
  .submit-button {
    margin-top: 20px;
  }
  @media screen and (max-width:767px) {
    .submenu-banner{
      width: 100%;
      height: auto;
    }
    .submenu-grid{
      flex-direction: column;
    }
    .sub-service-animate-section{
      width: auto;
      flex-direction: column;
    }
    .submenu-form{
      width: auto;
    }
    .sub-icon-box{
      width: auto;
      height: auto;
      margin: 1rem 0rem;
    }
  }
  
  